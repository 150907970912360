/* Font */
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Satisfy&display=swap');

/* Tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import css */
@import url('./css/login.css');
@import url('./css/home.css');
@import url('./css/music.css');
@import url('./css/gift.css');
@import url('./css/layout.css');
@import url('./css/image.css');
@import url('./css/setting.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Css dùng chung */
html,
body {
  cursor: url('https://1.bp.blogspot.com/-qbWo9mPKO2Y/YL9utYdQBdI/AAAAAAAAFs4/mtjGu6u2uGwtJsT4gZG4lbhLV1a5lG6OQCLcBGAsYHQ/s0/mouse-f1.png'),
    auto;
  margin: 0;
  padding: 0;
  height: 100vh;
}
a:hover {
  cursor: url('https://1.bp.blogspot.com/-nYv2dLl3oXY/YL9utYBCh8I/AAAAAAAAFtA/wII4lVw5w4k-4isGMY41heTqk8U4TJujgCLcBGAsYHQ/s0/mouse-f2.png'),
    auto;
}
img {
  max-width: 100%;
}
body {
  font-family: 'Segoe UI', sans-serif;
  color: #3e3e3e;
}
h1 {
  font-weight: lighter;
  text-align: center;
  color: #ff91af;
  padding: 4rem 1rem 1rem 1rem;
}
#wrapper {
  background-color: #f8ecf3;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f0bbcb;
  z-index: 1000;
}
.spinner {
  position: fixed;
  width: 100%;
  top: 70%;
  height: 30px;
  text-align: center;
  font-size: 10px;
}
.spinner,
.spinner-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border: 9px solid white;
  border-color: transparent white;
  margin: auto;
}
.spinner {
  width: 85px;
  height: 85px;
  -webkit-animation: spin 2.2s linear 0s infinite normal;
  -moz-animation: spin 2.2s linear 0s infinite normal;
  animation: spin 2.2s linear 0s infinite normal;
}
.spinner-inner {
  width: 40px;
  height: 40px;
  -webkit-animation: spinback 1.2s linear 0s infinite normal;
  -moz-animation: spinback 1.2s linear 0s infinite normal;
  animation: spinback 1.2s linear 0s infinite normal;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinback {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes spinback {
  from {
    -moz-transform: rotate(0);
  }
  to {
    -moz-transform: rotate(-360deg);
  }
}
@keyframes spinback {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(-360deg);
  }
}
