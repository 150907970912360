.tab-label {
  width: 100%;
  display: flex;
  height: 2.75rem;
  align-items: center;
  margin-bottom: 1rem;
}

.tab-label-item {
  flex: 1 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  border-radius: 0.75rem;
  font-weight: 500;
  font-size: 1.1rem;
  max-width: 240px;
  background-color: #6c757d11;
  color: #6c757d;
}

.tab-label-item-1 {
  margin-right: 0.5rem;
}

.tab-label-item-2 {
  margin-left: 0.5rem;
}

.tab-label-item.active {
  background-color: #ffccd555;
  color: #ff91af;
}

.gift-title {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.gift-title-icon {
  font-size: 1.75rem;
  height: 1.75rem;
  width: 1.75rem;
}

.gift-title-text {
  flex: 1;
  text-align: center;
  color: #ff91af;
  font-size: 1.5rem;
}

.tab-content-wrapper {
  padding-inline: 1rem;
  flex: 1;
  overflow: auto;
}

.tab-content-item {
  background-color: rgb(255 204 213 / 50%);
  padding: 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.25rem;
  position: relative;
  box-shadow: rgb(255 204 213 / 50%) 0px 4px 8px;
}

.tab-content-item.seen {
  background-color: rgb(255 204 213 / 30%);
}

.tab-content-item-icon {
  font-size: 2rem;
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
  position: relative;
}

.tab-content-item-icon span {
  font-size: 0.75rem;
  position: absolute;
  color: #ff91af;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
}

.tab-content-item-title {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tab-content-item-title .title {
  font-size: 1.1rem;
  font-weight: 600;
}

.tab-content-item-title .send-date {
  font-size: 0.9rem;
  opacity: 0.6;
}

.tab-content-item-seen {
  background-color: #ff91af;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100rem;
  margin-left: 0.5rem;
}

.gift-create {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000033;
  flex-direction: column;
  z-index: 9999;
}

.gift-create-content {
  background-color: #fcdce2;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: rgb(255 204 213 / 60%) 0px 8px 24px;
  flex-direction: column;
  z-index: 9999;
}

.gift-create-text {
  text-align: center;
  color: #ff91af;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.gift-create input,
.gift-create textarea {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0.5rem;
  padding: 10px 15px;
  display: block;
  font-size: 18px;
  transition-duration: 0.25s;
  margin-bottom: 12px;
  resize: none;
}

.gift-create .button-group {
  display: flex;
}

.gift-create button {
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  background-color: #ffccd5;
  border: 0;
  padding: 10px 15px;
  color: #ff91af;
  border-radius: 3px;
  cursor: pointer;
  font-size: 18px;
  transition-duration: 0.25s;
  flex: 1;
  border-radius: 0.5rem;
  margin-left: 0.25rem;
}

.gift-create .button-cancel {
  background-color: #fff;
  margin-left: 0;
  margin-right: 0.25rem;
}

.display-gift {
  min-height: 20rem;
  max-height: 40rem;
  background-color: #fcdce2;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 9999;
  box-shadow: rgb(255 204 213 / 60%) 0px 8px 24px;
}

.display-gift-heart-1,
.display-gift-heart-2,
.display-gift-heart-3,
.display-gift-heart-4 {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgb(255 139 201 / 15%);
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53z'/%3E%3C/svg%3E");
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.display-gift-heart-1 {
  width: 3rem;
  height: 3rem;
  transform: rotate(-30deg);
  top: 0.25rem;
  left: 0.25rem;
}

.display-gift-heart-2 {
  width: 2.25rem;
  height: 2.25rem;
  transform: rotate(25deg);
  top: 0.5rem;
  left: 2rem;
}

.display-gift-heart-3 {
  width: 2.5rem;
  height: 2.5rem;
  transform: rotate(20deg);
  bottom: 0.55rem;
  right: 0.75rem;
}

.display-gift-content {
  font-family: 'Dancing Script', cursive;
  margin: 2rem 1rem 2rem 1rem;
  overflow: auto;
  font-size: 1.5rem;
  line-height: 2rem;
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

.display-gift-content .signature {
  text-align: end;
}

.close-gift {
  position: absolute;
  font-size: 2rem;
  height: 2rem;
  width: 2rem;
  border-radius: 100rem;
  z-index: 10000;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.btn-option {
  font-size: 1.75rem;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.5rem;
  border-radius: 100rem;
}

.gift-heart {
  position: absolute;
  bottom: -0.75rem;
  right: 1rem;
  font-size: 1.1rem;
  height: 1.7rem;
  padding: 0.35rem;
  border-radius: 100rem;
  background-color: #ffccd5;
  color: #ff91af;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gift-heart span {
  font-size: 0.85rem;
  margin-left: 0.25rem;
}

.button-add {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  background-color: #ffccd5ff;
  border-radius: 100rem;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgb(255 204 213 / 60%) 0px 4px 12px;
}

.outside {
  z-index: 9998;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
