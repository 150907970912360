.right-buttons {
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.right-button {
  font-size: 1.75rem;
  height: 1.75rem;
  width: 1.75rem;
  margin-left: 0.75rem;
}

.side-bar {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #00000055;
  z-index: 100;
}

.side-bar-content {
  position: fixed;
  background-color: #f8ecf3;
  height: 100vh;
  width: 17.5rem;
  right: 0;
  padding: 1rem;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.75rem 0 0 0.75rem;
  transition: all 0.15s ease;
}

.hide .side-bar {
  display: none;
}

.hide .side-bar-content {
  right: -100%;
}

.side-bar-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  position: relative;
  cursor: pointer;
}

.side-bar-item .title {
  font-size: 1.15rem;
  color: #6c757d;
}

.side-bar-item svg {
  fill: #6c757d;
}

.side-bar-item .icon {
  color: #6c757d;
}

.side-bar-item.active,
.side-bar-item:hover,
.side-bar-item:active {
  background-color: #ffccd555;
  color: #ff91af;
}

.side-bar-item.active .icon,
.side-bar-item.active .title {
  color: #ff91af;
  font-weight: 500;
}

.side-bar-item.active svg,
.side-bar-item:hover svg,
.side-bar-item:active svg {
  fill: #ff91af;
}

.side-bar-item.logout:hover path,
.side-bar-item.logout:active path {
  stroke: #ff91af;
}

.side-bar-item .icon {
  margin-right: 0.5rem;
  font-size: 1.35rem;
}

.new-gift-back {
  background-color: #00000033;
  z-index: 999;
}

.new-gift-popup {
  background-color: #fcdce2;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  box-shadow: rgb(255 204 213 / 60%) 0px 8px 24px;
  flex-direction: column;
  z-index: 9999;
}

.envelope {
  position: relative;
  width: 300px;
  height: 200px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.flap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  border-top: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
  transform-origin: top;
  transform: rotateX(60deg);
  transition: transform 0.5s ease;
  background: linear-gradient(to bottom right, #fff 50%, #f1f1f1 50%);
  border-bottom: none;
}

.envelope:hover .flap {
  transform: rotateX(0deg);
}

.body {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
}

.bottom-flap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #fff;
  border-bottom: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
  transform-origin: bottom;
  transform: rotateX(-60deg);
  background: linear-gradient(to top right, #fff 50%, #f1f1f1 50%);
}
