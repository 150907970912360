.page-content {
  flex: 1;
  padding-inline: 1rem;
  padding-bottom: 1rem;
  overflow: auto;
}

.settings-area {
  background-color: rgb(255 204 213 / 30%);
  padding: 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;
  position: relative;
  box-shadow: rgb(255 204 213 / 50%) 0px 4px 8px;
}

.setting-input {
  box-sizing: border-box;
  appearance: none;
  outline: 0;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 0.5rem;
  padding: 10px 15px;
  display: block;
  font-size: 18px;
  transition-duration: 0.25s;
  resize: none;
  text-align: start;
  width: 100%;
}

.btn-save {
  appearance: none;
  outline: 0;
  background-color: #ffccd5;
  border: 0;
  padding: 10px 15px;
  color: #ff91af;
  border-radius: 3px;
  cursor: pointer;
  font-size: 18px;
  transition-duration: 0.25s;
  border-radius: 0.5rem;
  min-width: 120px;
  height: 48px;
}

.btn-cancel {
  appearance: none;
  outline: 0;
  border: 0;
  padding: 10px 15px;
  color: #ff91af;
  border-radius: 3px;
  cursor: pointer;
  font-size: 18px;
  transition-duration: 0.25s;
  border-radius: 0.5rem;
  min-width: 120px;
  background-color: #fff;
  margin-right: 0.25rem;
}

.btn-save.btn-loading {
  /* font-size: 1.75rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6c757d1a;
  color: #6c757d;
}
