.preview-image,
.list-image {
  width: 100%;
  height: 100%;
}

.preview-img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 3rem;
  margin: auto;
  margin-top: 4rem;
  position: relative;
}

.preview-buttons {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.button-upload {
  width: 6rem;
  height: 6rem;
  background-color: #ffccd5;
  border-radius: 100rem;
  font-size: 2.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgb(255 204 213 / 60%) 0px 4px 12px;
}

.button-upload.loading-btn-round {
  background-color: #6c757d1a;
  box-shadow: #6c757d1a 0px 4px 12px;
}

.preview-buttons .button-cancel {
  position: absolute;
  right: calc(50% - 8rem);
  transform: translateX(50%);
}

.preview-buttons .button-edit {
  position: absolute;
  right: calc(50% + 8rem);
  transform: translateX(50%);
}

.preview-input {
  outline: 0;
  border: 1px solid rgb(244 114 182 / 10%);
  background-color: rgb(244 114 182 / 5%);
  min-width: 250px;
  width: auto;
  border-radius: 100rem;
  padding: 6px 12px;
  display: block;
  text-align: center;
  font-size: 18px;
  transition-duration: 0.25s;
  margin: auto;
  margin-top: 2rem;
}

.image-item {
  width: 180px;
  height: 180px;
  border-radius: 1rem;
  object-fit: cover;
  margin-bottom: 2.75rem;
}

.list-image {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-inline: 1rem;
  overflow: auto;
  padding-bottom: 2rem;
}

.image-caption {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  text-align: center;
  font-size: 14px;
  background-color: #ffccd5;
  border-radius: 100rem;
}

.image-caption-view {
  margin-top: 1.5rem;
  font-size: 1.15rem;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  background-color: #ffccd5;
  width: max-content;
  padding: 6px 16px;
  border-radius: 100rem;
}

.reactEasyCrop_CropArea {
  border: none !important;
  box-shadow: none !important;
}

.slider-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.slider {
  -webkit-appearance: none;
  width: 12rem;
  height: 0.75rem;
  background: #ffccd5;
  outline: none;
  opacity: 0.7;
  border-radius: 100rem;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  background: rgb(255, 145, 175);
  border-radius: 100rem;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: rgb(255, 145, 175);
  cursor: pointer;
}
