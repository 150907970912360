#clock-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
#info {
  display: flex;
  justify-content: center;
  /* padding: 5rem 1rem; */
  align-items: center;
  flex: 1;
}
#clock-box #clock {
  background-color: #ffccd5;
  text-align: center;
  font-size: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 20px 0 #ffccd5;
}
.date,
.time {
  display: block;
}
.date {
  font-weight: 600;
}

.CircularProgressbar {
  position: absolute;
}

.time {
  bottom: -3rem;
}
.avt {
  width: 9.5rem;
  height: 9.5rem;
  margin-bottom: 0.75rem;
  position: relative;
}
#info .one,
#info .two {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avt img {
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  padding: 0.75rem;
  width: 100%;
  height: 100%;
}

.footer {
  text-align: center;
  font-size: 1.6rem;
  font-weight: lighter;
  padding: 1rem 1rem 2rem 1rem;
}

#name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}

#name svg {
  margin-left: 4px;
}

.add-heart {
  position: relative;
  font-size: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23ff91af' d='m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53z'/%3E%3C/svg%3E");
  background-size: 100%;
}
.add-heart span {
  position: absolute;
  display: block;
  font-size: inherit;
  width: 1em;
  height: 1em;
  bottom: 0.5em;
}
.add-heart span:before {
  content: '';
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23ff91af' d='m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53z'/%3E%3C/svg%3E");
  background-size: 100%;
}
.add-heart span:nth-child(1) {
  -webkit-animation: heart-fly-1 1s infinite;
  animation: heart-fly-1 1s infinite;
}
.add-heart span:nth-child(1):before {
  width: 0.9em;
  height: 0.9em;
  transform: rotate(-10deg);
}
.add-heart span:nth-child(2) {
  -webkit-animation: heart-fly-2 1s infinite;
  animation: heart-fly-2 1s infinite;
}
.add-heart span:nth-child(2):before {
  width: 0.8em;
  height: 0.8em;
  transform: rotate(10deg);
}
.add-heart span:nth-child(3) {
  -webkit-animation: heart-fly-3 1s infinite;
  animation: heart-fly-3 1s infinite;
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.add-heart span:nth-child(3):before {
  width: 0.7em;
  height: 0.7em;
  transform: rotate(20deg);
}
.add-heart span:nth-child(4) {
  -webkit-animation: heart-fly-4 1s infinite;
  animation: heart-fly-4 1s infinite;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.add-heart span:nth-child(4):before {
  width: 0.6em;
  height: 0.6em;
  transform: rotate(5deg);
}
.add-heart span:nth-child(5) {
  -webkit-animation: heart-fly-5 1s infinite;
  animation: heart-fly-5 1s infinite;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.add-heart span:nth-child(5):before {
  width: 0.5em;
  height: 0.5em;
  transform: rotate(-20deg);
}
@-webkit-keyframes heart-fly-1 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.8;
    transform: translate(10%, -100%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@keyframes heart-fly-1 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.8;
    transform: translate(10%, -100%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@-webkit-keyframes heart-fly-2 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.7;
    transform: translate(-80%, -160%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@keyframes heart-fly-2 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.7;
    transform: translate(-80%, -160%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@-webkit-keyframes heart-fly-3 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.6;
    transform: translate(120%, -170%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@keyframes heart-fly-3 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.6;
    transform: translate(120%, -170%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@-webkit-keyframes heart-fly-4 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.5;
    transform: translate(30%, -250%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@keyframes heart-fly-4 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.5;
    transform: translate(30%, -250%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@-webkit-keyframes heart-fly-5 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.4;
    transform: translate(-60%, -250%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}
@keyframes heart-fly-5 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  70% {
    opacity: 0.4;
    transform: translate(-60%, -250%);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.not-seen {
  background-color: #ff91af;
  color: #fff;
  border-radius: 100rem;
  height: 1rem;
  width: 1rem;
  font-size: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
}

.zodiac {
  color: #fff;
  padding: 0 0.4rem 0 0.5rem;
  border-radius: 100rem;
  font-size: 0.75rem;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fb6f92;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.girl-age,
.boy-age {
  background-color: #ff91af;
  color: #fff;
  padding: 0 0.5rem 0 0.4rem;
  border-radius: 100rem;
  font-size: 0.75rem;
}

.boy-age {
  background-color: #28aef0;
}

/* src/components/LazyImage.css */
.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 2.75rem; /* Đảm bảo tỉ lệ khung hình vuông */
  overflow: hidden;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 180px;
  height: 180px;
  /* background-color: #ff91ae1a; */
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 2rem;
  /* color: #ff91ae; */
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.3s;
}

.image-container span.lazy-load-image-loaded + .loading {
  opacity: 0;
}

.skeleton {
  animation: skeleton-loading 0.75s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #ff91ae1a;
  }
  100% {
    background-color: #ff91ae0a;
  }
}

.count-item {
  background-color: #ffccd5;
  border-radius: 1rem;
  box-shadow: 0 4px 20px 0 #ffccd5;
}

.count-item .number {
  font-weight: 600;
}
