.audio {
  display: none;
}

.lyric {
  padding: 3rem 2.5rem;
  font-family: 'IBM Plex Serif', serif;
  font-weight: 600;
  font-size: 2.9rem;
  color: #6c757d;
  z-index: 9;
  /* text-align: center; */
}

.lyric-fade {
  /* display: inline; */
  animation: fadeIn 1s both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.music-bubbles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.music-bubbles div {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  bottom: -160px;
  transition-timing-function: linear;
  animation: bubble-2 15s both;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffb8d4' d='m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53z'/%3E%3C/svg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.music-bubbles div:nth-child(1) {
  left: calc(50% - 135px);
  bottom: calc(50% - 760px);
  translate: -50%;
}
.music-bubbles div:nth-child(2) {
  left: calc(50% - 135px);
  animation-delay: 2s;
  animation-duration: 13s;
  bottom: -260px;
  bottom: calc(50% - 710px);
  translate: -50%;
}
.music-bubbles div:nth-child(3) {
  left: calc(50% - 90px);
  animation-duration: 11s;
  animation-delay: 4s;
  bottom: calc(50% - 680px);
  translate: -50%;
}
.music-bubbles div:nth-child(4) {
  left: calc(50% - 90px);
  animation-delay: 6s;
  animation-duration: 9s;
  bottom: calc(50% - 805px);
  translate: -50%;
}
.music-bubbles div:nth-child(5) {
  left: calc(50% - 45px);
  bottom: calc(50% - 680px);
  translate: -50%;
}
.music-bubbles div:nth-child(6) {
  left: calc(50% - 45px);
  animation-delay: 5s;
  animation-duration: 10s;
  bottom: calc(50% - 840px);
  translate: -50%;
}
.music-bubbles div:nth-child(7) {
  left: 50%;
  animation-delay: 2.5s;
  animation-duration: 12.5s;
  bottom: calc(50% - 875px);
  translate: -50%;
}
.music-bubbles div:nth-child(8) {
  left: 50%;
  animation-delay: 4s;
  animation-duration: 11s;
  bottom: calc(50% - 710px);
  translate: -50%;
}
.music-bubbles div:nth-child(9) {
  left: calc(50% + 45px);
  animation-delay: 3.5s;
  animation-duration: 11.5s;
  bottom: calc(50% - 680px);
  translate: -50%;
}
.music-bubbles div:nth-child(10) {
  left: calc(50% + 45px);
  animation-delay: 1.5s;
  animation-duration: 13.5s;
  bottom: calc(50% - 840px);
  translate: -50%;
}
.music-bubbles div:nth-child(11) {
  left: calc(50% + 90px);
  animation-delay: 5.5s;
  animation-duration: 9.5s;
  bottom: calc(50% - 680px);
  translate: -50%;
}

.music-bubbles div:nth-child(12) {
  left: calc(50% + 90px);
  animation-delay: 0.5s;
  animation-duration: 14.5s;
  bottom: calc(50% - 805px);
  translate: -50%;
}

.music-bubbles div:nth-child(13) {
  left: calc(50% + 135px);
  animation-delay: 7s;
  animation-duration: 8s;
  bottom: calc(50% - 710px);
  translate: -50%;
}

.music-bubbles div:nth-child(14) {
  left: calc(50% + 135px);
  animation-delay: 3s;
  animation-duration: 12s;
  bottom: calc(50% - 760px);
  translate: -50%;
}

.music-bubbles div {
  animation: twinkle 0.8s infinite alternate ease-in, bubble-2 15s both;
}

.music-bubbles div::after {
  content: '';
  display: block;
  margin-top: -32.5px;
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  filter: blur(13px);
  opacity: 0.6;
}

@keyframes bubble-2 {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-750px) rotate(720deg);
  }
}

@keyframes twinkle {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.pear {
  /* position: absolute; */
  font-size: 4rem;
  /* left: 11rem;
  bottom: 28rem; */
}

.music-bubbles-2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.music-bubbles-2 div {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  bottom: -100px;
  animation: bubble 25s infinite, twinkle 0.8s infinite alternate ease-in;
  transition-timing-function: linear;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffb8d4' d='m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53z'/%3E%3C/svg%3E");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.music-bubbles-2 div:nth-child(1) {
  left: 20%;
  width: 80px;
  height: 80px;
  bottom: -220px;
  animation-duration: 14s;
}
.music-bubbles-2 div:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  animation-delay: 2s;
  animation-duration: 13s;
  bottom: -580px;
}
.music-bubbles-2 div:nth-child(3) {
  left: 60%;
  width: 100px;
  height: 100px;
  animation-duration: 14.5s;
  bottom: -640px;
}
.music-bubbles-2 div:nth-child(4) {
  left: 70%;
  width: 60px;
  height: 60px;
  bottom: -240px;
  animation-duration: 15.5s;
}

@media (min-width: 768px) {
  .lyric {
    font-size: 4.5rem;
  }
  .music-bubbles div:nth-child(1) {
    left: calc(50% - 165px);
    bottom: calc(50% - 770px);
  }
  .music-bubbles div:nth-child(2) {
    left: calc(50% - 165px);
    bottom: calc(50% - 710px);
  }
  .music-bubbles div:nth-child(3) {
    left: calc(50% - 110px);
    bottom: calc(50% - 660px);
  }
  .music-bubbles div:nth-child(4) {
    left: calc(50% - 110px);
    bottom: calc(50% - 820px);
  }
  .music-bubbles div:nth-child(5) {
    left: calc(50% - 55px);
    bottom: calc(50% - 660px);
  }
  .music-bubbles div:nth-child(6) {
    left: calc(50% - 55px);
    bottom: calc(50% - 865px);
  }
  .music-bubbles div:nth-child(7) {
    left: calc(50% - 0px);
    bottom: calc(50% - 910px);
  }
  .music-bubbles div:nth-child(8) {
    left: calc(50% - 0px);
    bottom: calc(50% - 710px);
  }
  .music-bubbles div:nth-child(9) {
    left: calc(50% + 55px);
    bottom: calc(50% - 660px);
  }
  .music-bubbles div:nth-child(10) {
    left: calc(50% + 55px);
    bottom: calc(50% - 865px);
  }
  .music-bubbles div:nth-child(11) {
    left: calc(50% + 110px);
    bottom: calc(50% - 660px);
  }
  .music-bubbles div:nth-child(12) {
    left: calc(50% + 110px);
    bottom: calc(50% - 820px);
  }
  .music-bubbles div:nth-child(13) {
    left: calc(50% + 165px);
    bottom: calc(50% - 710px);
  }
  .music-bubbles div:nth-child(14) {
    left: calc(50% + 165px);
    bottom: calc(50% - 770px);
  }
}

@media (min-width: 1280px) {
  .lyric {
    font-size: 5.5rem;
  }
  .music-bubbles div:nth-child(1) {
    left: calc(50% - 195px);
    bottom: calc(50% - 770px);
  }
  .music-bubbles div:nth-child(2) {
    left: calc(50% - 195px);
    bottom: calc(50% - 710px);
  }
  .music-bubbles div:nth-child(3) {
    left: calc(50% - 130px);
    bottom: calc(50% - 660px);
  }
  .music-bubbles div:nth-child(4) {
    left: calc(50% - 130px);
    bottom: calc(50% - 820px);
  }
  .music-bubbles div:nth-child(5) {
    left: calc(50% - 65px);
    bottom: calc(50% - 660px);
  }
  .music-bubbles div:nth-child(6) {
    left: calc(50% - 65px);
    bottom: calc(50% - 865px);
  }
  .music-bubbles div:nth-child(7) {
    left: calc(50% - 0px);
    bottom: calc(50% - 910px);
  }
  .music-bubbles div:nth-child(8) {
    left: calc(50% - 0px);
    bottom: calc(50% - 710px);
  }
  .music-bubbles div:nth-child(9) {
    left: calc(50% + 65px);
    bottom: calc(50% - 660px);
  }
  .music-bubbles div:nth-child(10) {
    left: calc(50% + 65px);
    bottom: calc(50% - 865px);
  }
  .music-bubbles div:nth-child(11) {
    left: calc(50% + 130px);
    bottom: calc(50% - 660px);
  }
  .music-bubbles div:nth-child(12) {
    left: calc(50% + 130px);
    bottom: calc(50% - 820px);
  }
  .music-bubbles div:nth-child(13) {
    left: calc(50% + 195px);
    bottom: calc(50% - 710px);
  }
  .music-bubbles div:nth-child(14) {
    left: calc(50% + 195px);
    bottom: calc(50% - 770px);
  }
}

@media (min-width: 1536px) {
  .lyric {
    font-size: 5.5rem;
  }
  .music-bubbles div:nth-child(1) {
    left: calc(50% - 225px);
    bottom: calc(50% - 780px);
  }
  .music-bubbles div:nth-child(2) {
    left: calc(50% - 225px);
    bottom: calc(50% - 700px);
  }
  .music-bubbles div:nth-child(3) {
    left: calc(50% - 150px);
    bottom: calc(50% - 640px);
  }
  .music-bubbles div:nth-child(4) {
    left: calc(50% - 150px);
    bottom: calc(50% - 840px);
  }
  .music-bubbles div:nth-child(5) {
    left: calc(50% - 75px);
    bottom: calc(50% - 640px);
  }
  .music-bubbles div:nth-child(6) {
    left: calc(50% - 75px);
    bottom: calc(50% - 895px);
  }
  .music-bubbles div:nth-child(7) {
    left: calc(50% - 0px);
    bottom: calc(50% - 940px);
  }
  .music-bubbles div:nth-child(8) {
    left: calc(50% - 0px);
    bottom: calc(50% - 700px);
  }
  .music-bubbles div:nth-child(9) {
    left: calc(50% + 75px);
    bottom: calc(50% - 640px);
  }
  .music-bubbles div:nth-child(10) {
    left: calc(50% + 75px);
    bottom: calc(50% - 895px);
  }
  .music-bubbles div:nth-child(11) {
    left: calc(50% + 150px);
    bottom: calc(50% - 640px);
  }
  .music-bubbles div:nth-child(12) {
    left: calc(50% + 150px);
    bottom: calc(50% - 840px);
  }
  .music-bubbles div:nth-child(13) {
    left: calc(50% + 225px);
    bottom: calc(50% - 700px);
  }
  .music-bubbles div:nth-child(14) {
    left: calc(50% + 225px);
    bottom: calc(50% - 780px);
  }
}

@media (min-width: 1800px) {
  .lyric {
    font-size: 6.5rem;
  }
}
