.login-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form input {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgb(244 114 182 / 10%);
  background-color: rgb(244 114 182 / 5%);
  width: 250px;
  border-radius: 3px;
  padding: 10px 15px;
  display: block;
  text-align: center;
  font-size: 18px;
  /* color: #fff; */
  transition-duration: 0.25s;
  margin-bottom: 12px;
}

.login-form button {
  -webkit-appearance: none;
  appearance: none;
  outline: 0;
  background-color: rgb(255 255 255 / 50%);
  border: 0;
  padding: 10px 15px;
  color: #ff91af;
  border-radius: 3px;
  width: 250px;
  cursor: pointer;
  font-size: 18px;
  transition-duration: 0.25s;
}

.login-other {
  color: #ff91af;
  position: absolute;
  bottom: 3rem;
  font-size: 1.1rem;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000033;
}

.login-bubbles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.login-bubbles div {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: rgb(244 114 182 / 15%);
  bottom: -160px;
  animation: bubble 25s infinite;
  transition-timing-function: linear;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='m12 21.35l-1.45-1.32C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5c0 3.77-3.4 6.86-8.55 11.53z'/%3E%3C/svg%3E");
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.login-bubbles div:nth-child(1) {
  left: 10%;
}

.login-bubbles div:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  animation-delay: 2s;
  animation-duration: 17s;
}

.login-bubbles div:nth-child(3) {
  left: 25%;
  animation-delay: 4s;
}

.login-bubbles div:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-duration: 22s;
  background-color: rgb(244 114 182 / 25%);
}

.login-bubbles div:nth-child(5) {
  left: 70%;
}

.login-bubbles div:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  animation-delay: 3s;
  background-color: rgb(244 114 182 / 20%);
}

.login-bubbles div:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  animation-delay: 7s;
}

.login-bubbles div:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  animation-delay: 15s;
  animation-duration: 40s;
}

.login-bubbles div:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  animation-delay: 2s;
  animation-duration: 40s;
  background-color: rgb(244 114 182 /30%);
}

.login-bubbles div:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  animation-delay: 11s;
}

#password {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

#password-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

#dot {
  position: relative;
  display: flex;
  margin-bottom: 3rem;
}

.dot-false {
  animation: shake 0.2s infinite alternate;
}

#dot-item {
  width: 1rem;
  height: 1rem;
  border: 1px solid;
  border-radius: 100rem;
  margin-inline: 0.5rem;
}

#dot-item.filled {
  background-color: #3e3e3e;
}

#number {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.number-item {
  width: 4.5rem;
  height: 4.5rem;
  border: 1px solid;
  border-radius: 100rem;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-item.none {
  border: none;
}

@keyframes bubble {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-800px) rotate(720deg);
  }
}

@keyframes shake {
  0% {
    left: 5px;
  }
  100% {
    left: -5px;
  }
}

.login-loading {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #ff91ae;
}
